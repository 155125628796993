@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;1,400;1,500;1,600&display=swap');

*{
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
  

}
.formofcoverpage{
    padding-top: 20px;
}
.form {
    
    display: flex;
    width: 100%;
    justify-content: center;
   
}

.input-field{
   display: flex;
   justify-content: space-between;
   padding: 5px;
}
.inputLayer{
    width: 50%;
    
}
 .input-field-btn{
     display: flex;
     justify-content: center;
 }
 button{
    margin: 20px;
    padding:8px  20px 8px 20px;
    border-radius: 10px;
    border:none;
    color: white;
    font-size: 20px;
    background: #121886;
 }
 .dateofexpname{
 
    width: auto!important;
    height: 20px;
 
 }
.input-field input{
    outline: none;
    border: 1px solid rgba(212, 212, 212, 0.897);
    width: 400px;
    height: 20px;
 padding: 10px;
 background: none;
}
.input-field select{
    outline: none;
    border: 1px solid rgba(212, 212, 212, 0.897);
    width: 421px;
    height: 40px;
 padding: 10px;
 background: none;
}

.howpeoplesee{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 244, 244);
    margin: 0px;
     
    
}
body{
    margin: 0px;
    padding:0px
   
}

@media print {
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    .formofcoverpage{
        padding-top: 0px!important;
    }
    body {
        padding-top: 72px;
        padding-bottom: 72px ;
    }
    .howpeoplesee{
         background: none;
    }
    .headerback{
        display: none;
    }
   
}



@media only screen and (max-width: 600px) {
    .inputLayer{
        width: 95%;
        
    }
 
     .input-field-btn{
         display: flex;
         justify-content: center;
     }
     button{
        margin: 20px;
        padding:8px  20px 8px 20px;
        border-radius: 10px;
        border:none;
        color: white;
        font-size: 20px;
        background: #121886;
     }
     .dateofexpname{
     
        width: 90px!important;
     
     }
    .input-field input{
       
        width: 200px;
      
    }
    .input-field select{
        outline: none;
        border: 1px solid rgba(212, 212, 212, 0.897);
        width: 221px;
        height: 40px;
     padding: 10px;
    }
  }
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;1,400;1,500;1,600&display=swap);
*{
    font-family: 'Open Sans', sans-serif;
  

}
.printbutton{
    margin: 20px;
   padding:8px  20px 8px 20px;
   border-radius: 10px;
   border:none;
   color: white;
   font-size: 20px;
   background: #121886;
}
#elementH{
   
    width: 100%;
    display: flex;
    justify-content: center;
}

.howpeoplesee{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 244, 244);
    margin: 0px;
     
   
}
body{
    margin: 0px;
    padding:0px
   
}
*{
    font-family: 'Open Sans', sans-serif;
  

}

@media print {

     /* body {
        height:100%; 
        margin: 0 !important; 
        padding:0 !important;
        overflow: hidden;
      }
     */
  
  }

  @page {
    size: A4;
   
  }
  @media print {

    /* ... the rest of the rules ... */
}
  
.firstsection{
    text-align: center;
}

.secondsection{
    display: flex;
    padding-top: 40px;
 
}

.secondsection>.firstlayer{
    flex-basis: 50%;
}

.secondsection>.secondlayer{
    flex-basis: 50%;
    text-align: center;
}
.lastsection{
    display: flex;
    padding-top: 40px;
}


.lastsection>.firstlayer{
    flex-basis: 50%;
}

.lastsection>.secondlayer{
    flex-basis: 50%;
    text-align: center;
}

.middlelayer>img{
    padding-top: 50px;
}

.fontsize{
    font-size: 20px;
}

.firstsection>img{
    width: 200px;
}

.title1{
font-size: 25px;
 
}
.title2{
    font-size: 22px;  
    line-height: 20px;
}



.pdf{
    background-color: white;
      padding: 40px;  
}

.inputboxdesign{
    outline: none;
    border: none;
   /* width:100px; */
   width:auto;
   font-size: 20px;
}

.lastfont{
    font-size: 15px;
}

.printbutton{
    margin: 20px;
   padding:8px  20px 8px 20px;
   border-radius: 10px;
   border:none;
   color: white;
   font-size: 20px;
   background: #121886;
}
#elementH{
   
    width: 100%;
    display: flex;
    justify-content: center;
}






 
  header
{
    display:none;
}
 
footer
{
    display:none;
}



*{
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
  

}
.formofcoverpage{
    padding-top: 20px;
}
.form {
    
    display: flex;
    width: 100%;
    justify-content: center;
   
}

.input-field{
   display: flex;
   justify-content: space-between;
   padding: 5px;
}
.inputLayer{
    width: 50%;
    
}
 .input-field-btn{
     display: flex;
     justify-content: center;
 }
 button{
    margin: 20px;
    padding:8px  20px 8px 20px;
    border-radius: 10px;
    border:none;
    color: white;
    font-size: 20px;
    background: #121886;
 }
 .dateofexpname{
 
    width: auto!important;
    height: 20px;
 
 }
.input-field input{
    outline: none;
    border: 1px solid rgba(212, 212, 212, 0.897);
    width: 400px;
    height: 20px;
 padding: 10px;
 background: none;
}
.input-field select{
    outline: none;
    border: 1px solid rgba(212, 212, 212, 0.897);
    width: 421px;
    height: 40px;
 padding: 10px;
 background: none;
}

.howpeoplesee{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 244, 244);
    margin: 0px;
     
    
}
body{
    margin: 0px;
    padding:0px
   
}

@media print {
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    .formofcoverpage{
        padding-top: 0px!important;
    }
    body {
        padding-top: 72px;
        padding-bottom: 72px ;
    }
    .howpeoplesee{
         background: none;
    }
    .headerback{
        display: none;
    }
   
}



@media only screen and (max-width: 600px) {
    .inputLayer{
        width: 95%;
        
    }
 
     .input-field-btn{
         display: flex;
         justify-content: center;
     }
     button{
        margin: 20px;
        padding:8px  20px 8px 20px;
        border-radius: 10px;
        border:none;
        color: white;
        font-size: 20px;
        background: #121886;
     }
     .dateofexpname{
     
        width: 90px!important;
     
     }
    .input-field input{
       
        width: 200px;
      
    }
    .input-field select{
        outline: none;
        border: 1px solid rgba(212, 212, 212, 0.897);
        width: 221px;
        height: 40px;
     padding: 10px;
    }
  }
.headerback{
    width: 100%;
    height: 70px;
    background: rgb(219, 219, 219);
    /* margin-top: -21px; */
    color: rgb(0, 0, 0);
    display: flex;
 
  justify-content: center;
}
.headerback h1{
   
    margin-top: 10px;
}
img{
   
}

@media only screen and (max-width: 600px) {
    .headerback{
        width: 100%;
        height: 70px;
        background: rgb(219, 219, 219);
     }
    .headerback h1{
        padding-top:25px ;
        font-size: 20px;
        text-align: center;
    }
    
  }

.headerback{
    width: 100%;
    height: 70px;
    background: rgb(219, 219, 219);
    /* margin-top: -21px; */
    color: rgb(0, 0, 0);
    display: flex;
 
  justify-content: center;
}
.headerback h1{
   
    margin-top: 10px;
}
img{
   
}

@media only screen and (max-width: 600px) {
    .headerback{
        width: 100%;
        height: 70px;
        background: rgb(219, 219, 219);
     }
    .headerback h1{
        padding-top:25px ;
        font-size: 20px;
        text-align: center;
    }
    
  }